import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom'
import FormTemplate from '../components/FormTemplate'
import EditTable from '../components/EditTable'
import {EMPLOYEES} from '../services/constants'
import StatusMessage from '../components/StatusMessage'
import serverFetch from '../services/serverFetch';
import serverPost from '../services/serverPost'
import {search} from '../services/search'
import { useSharedState } from '../store';


const styles = {
    container: {
        marginTop:80,
        display:'flex',
        flexDirection:'column',
        width:'100vw',
        overflow:'auto',
    },
    item: {
        margin:'auto',
    },
}

const fields = [
    {
        type:'text',
        label:'För- och efternamn',
        name:'namn',
        tooltip:'Kundens för och efternamn',
        required:true,
        autoFocus:true,
        maxlength:159,
    },
    {
        type:'text',
        label:'Telefonnummer',
        name:'mobil',
        tooltip:'Kundens mobilnummer',
        required:true,
        maxlength:16,
    },
    {
        type:'text',
        label:'E-mail',
        name:'email',
        tooltip:'Kundens email',
        hiddenIf:'fakturaKund',
        anchor:true,
        maxlength:100,
    },
    {
        type:'text',
        label:'E-mail',
        name:'email',
        tooltip:'Kundens email',
        required:'true',
        notHiddenIf:'fakturaKund',
        anchor:true,
        maxlength:100,
    },
    {
        type:'text',
        label:'Organisationsnummer',
        name:'orgnr',
        placeholder:'Ex: 551231-4604',
        tooltip:'Kundens organisationsnummer (Ex: 551131-4672)',
        required:'true',
        notHiddenIf:'fakturaKund',
        maxlength:13,
    },
    {
        type:'checkbox',
        label:'Kunden betalar via faktura',
        name:'fakturaKund',
        tooltip:'Kunden betalar via faktura',
    },
    {
        type:'checkbox',
        label:'Stoppa efter det att linje i söklista klickad',
        name:'stopRedirect',
        tooltip:'',
    },
]

const MODE = {
    SEARCH:1,
    NEW:2
}


const resultFields = [
    {
        type:'text',
        label:'För- och efternamn',
        name:'namn',
        tooltip:'Kundens fullständiga namn',
        required:true,
    },
    {
        type:'text',
        label:'E-mail',
        name:'email',
        tooltip:'Kundens email',
    },
    {
        type:'text',
        label:'Telefonnummer',
        name:'mobil',
        tooltip:'Kundens mobilnummer',
    },
    {
        type:'number',
        label:'Fakturakund',
        name:'fakturaKund',
        tooltip:'Kundens mobilnummer',
    }
]

const tableName='tbl_customer'

export default () => {
    const navigate = useNavigate()
    const [global, setGlobal] = useSharedState()
    const [list, setList] = useState([])
    const [statusMessage, setStatusMessage] = useState({})
    const [value, setValue] = useState({})
    useEffect(()=>{
        setGlobal({...global, pageName:'Inlämning - Kunduppgifter'})
    }, [])
    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
        let timer = setTimeout(() => setStatusMessage({}), 10000);
    }

    const handleNavigate = kundId => {
        const irl = '/in/' + kundId
        if (kundId != 0) {
            setGlobal({...global, pageName:'Inlämning - Inlämnat objekt'})
            navigate(irl)
        } 
    }

    const handleSaveCallback = reply => {
        const {status, record, message} = reply
        //alert('Reply =' + JSON.stringify(record))
        if (status === 'OK') {
            if (record.id === 0) {
                handleStatus({backgroundColor:'red'}, 'Kund ej sparad i database')
            } else {
            // alert(JSON.stringify(record))
                handleStatus({backgroundColor:'green'}, 'Kund sparad i database')
                handleNavigate(record.id)
            }    
        } else {
            const mes = 'ERROR: Kunduppgifter kunde inte sparas i databasens tabell. Kontakta WEB-administratören'
            setStatusMessage({backgroundColor:'red'}, 'ERROR: misslyckades att spara kund i database (troligen duplicat av samma namn och telefonnnummer)')
        }    
    }        

    const handleSaveCallback3 = (reply, id) => {
        const {status} = reply        
        if (status === 'OK') {
            handleNavigate(id)
        } else {
            const message = 'ERROR: Kunde ej uppdatera kundreistret med nya data'
            setStatusMessage({backgroundColor:'red'}, message)
        }   
    }

    const handleSearchCallback = list => {
        if (list.length === 0) {
            setGlobal({...global, pageName:'Inlämning - Kunduppgifter'})
            const record = {
                ...value,
                id:undefined
            }
            serverPost('/insertIgnoreRow', '', '', {tableName, record}, handleSaveCallback)
        } else {
            let rec = list.find(it => value.namn === it.namn && value.mobil === it.mobil) 
            if (rec) {
                if (!!value.falkturakund || !!value.email || !!value.orgnr) {
                    const updRecord =  
                    {
                        email:value.email?value.email:'',
                        fakturaKund:value.fakturaKund,
                        orgnr:value.orgnr,
                    }
                    serverPost('/updateRow', '', '', {tableName, record:updRecord, id:rec.id}, reply =>handleSaveCallback3(reply, rec.id))
                } else {   
                    handleNavigate(rec.id)
                }    
            } else {  
                const record = {
                    ...value,
                    id:undefined
                }
                serverPost('/insertIgnoreRow', '', '', {tableName, record}, handleSaveCallback)
            }    
        } 
    }

    // Handeling of button Continue (Fortsätt)
    const handleContinue = () => {
        //handleStatus({backgroundColor:'green'}, 'Sparar kund i database')
        search(tableName, value, handleSearchCallback) 
    }

    const handleClickLine = record => {
        // alert('handleClickLine rec:' + JSON.stringify(rec))
        if (!record.id) {
            alert('ERROR: Detta svar från databasen kundens kundnummer (kundId), kontakta WEB-administratören:' +  JSON.stringify(record))
        } else if (!record.namn) {
            alert('ERROR: Kundens namn saknas i databasen, kontakta WEB-administratören:' +  JSON.stringify(record))
        } else {
            // alert(JSON.stringify(rec))
            if (value.stopRedirect) {
                setGlobal({...global, pageName:'Inlämning - Kunduppgifter'})
                setValue({...record, stopRedirect:value.stopRedirect})
                setList([])
            } else {
                handleNavigate(record.id)
            }   
        }    
    } 

    const handleSearchReply = list => {
        if (list.length === 0) {
            setGlobal({...global, pageName:'Inlämning - Kunduppgifter'})
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i database')    
        } else if (list.length === 1) {
            setGlobal({...global, pageName:'Inlämning - Kunduppgifter'})
            const rec=list[0]
            // handleStatus({backgroundColor:'green'}, 'Kunddata hämtade från databasen')
            setValue({...rec, stopRedirect:value.stopRedirect})
        } else {
            setGlobal({...global, pageName:'Inlämning - Sökning'})
            // handleStatus({backgroundColor:'green'}, 'Flera sökresultat')    
            setList(list)
        }    
    }

    const handleSearch = () => {
        const searchValue = {...value}
        delete searchValue.stopRedirect // stopRedirect must bit exust ub tge search criteria
        search(tableName, searchValue, handleSearchReply) 
    }

    const handleReset = () => {
        setValue({})
        setList([])
    }

    const buttons=
        [
            {
                style:{color:'black', borderColor:'black'},
                type:'button',
                label:'Sök',
                handleClick:handleSearch
            },    
            {
                style:{color:'black', borderColor:'black'},
                type:'button',
                label:'Fortsätt',
                required:true,
                handleClick:(()=>handleContinue())
            },    
            {
                type:'button',
                style:{color:'black', borderColor:'black'},
                label:'Rensa',
                handleClick:handleReset
            },    
        ]

    return(    
        <div style={styles.container}>
            <div style={styles.item}>
                    <FormTemplate 
                        buttons={buttons}
                        fields={fields} 
                        value={value}
                        setValue={setValue}
                        setList={setList}
                        handleStatus={handleStatus}
                        handlePressEnter={handleSearch}
                />
            </div>    
                <div style={styles.item}>
                    <EditTable 
                        tableName={tableName}
                        list={list} 
                        setList={setList} 
                        resultFields={resultFields}
                        handleStatus={handleStatus}  
                        handleClickLine={handleClickLine}
                    />
            </div>
            <p/>    
            <StatusMessage style={statusMessage.style} message={statusMessage.message} />
        </div>
    )
}    
