export const defaultDate = () =>{
    const today = new Date();
    const date = today.setDate(today.getDate()); 
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    return defaultValue
} 

export const addDefaultDateToValue = (fields, val) => {
    let newValue = val
    fields.forEach(fld => {
        if (fld.useDefaultDate === true && fld.type === 'date' && val[fld.name] === undefined) {
            if (fld.notHiddenIf?val[fld.notHiddenIf]?true:false:true) {
                if (fld.hiddenIf?val[fld.hiddenIf]?false:true:true) {
                    newValue = {...newValue, [fld.name]:defaultDate()}
                }    
            }
        }
    })
    //alert(JSON.stringify(newValue))
    return newValue
}    

export const sleep = ms =>  new Promise(resolve => setTimeout(resolve, ms))

export const allowedToUpdate = val => {
    if (val.kontaktadAvhamtningAv === null && val.utlamnadAv === null) {
        return true
    } else {
    // eslint-disable-next-line no-restricted-globals
        if (confirm('Vill du spara ändringarna till servicerapporten ?')) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('ÄR DU HELT SÄKER (Du har kryssat i kontaktad via eller utlämnad av) ?')) {
                return true
            }    
        } else {
            return false
        }
    }    
}

export const allowedToUpdateByFields = (val, fields) => {
    var allNull=true
    fields.forEach(it => {
        if (val[it] !== null) {
            allNull=false
        }
    }) 
    if (allNull) {
        return true
    } else {
    // eslint-disable-next-line no-restricted-globals
        if (confirm('Vill du spara ändringarna till servicerapporten ?')) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('ÄR DU HELT SÄKER (Du har kryssat i kontaktad via eller utlämnad av) ?')) {
                return true
            }    
        } else {
            return false
        }
    }    
}

export const adjustKostnad = kostnad => {
    

}

