import React, {useState, useEffect} from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import serverPost from '../services/serverPost'
import {search} from '../services/search'


const styles = {
    button:{
        marginLeft:'5px',
        marginRight:'5px',
        marginBottom:'10px',
        borderWidth:'2px',
        height:50,
        rowor:'#FFFFFF',
        borderrowor:'#FFFF87',
        backgroundrowor:'transparent'
    }

}

const KOMPONENTER =  [
    {
        komponent:'Kondensator',
        antal:4,
        pris:1000
    },
    {
        komponent:'Spole',
        antal:3,
        pris:100
    },
    {
        komponent:'Motstånd',
        antal:2,
        pris:25
    },
]

export default props => {
    const {tableName, id, setTotal, rows, setRows,  handleStatus} = props
    // const [rows, setRows] = useState([])
    const [row, setRow] = useState({})
    const [mouseLeave, setMouseLeave] = useState(0)

    const handleSearchReply = list => {
        //alert(JSON.stringify(list))
        if (list.length === 0) {
            if (!handleStatus) {
                alert('Varning: Fick inget resultat vid sökning i database')
            }    
        } else {
            setTotal(calcTotal(list))
            setRows(list)
        }
    }
    useEffect(()=>{
        // if (handleStatus) {handleStatus({color:'white', backgroundColor:'green'}, 'Söker ...')}
        search(tableName, {id}, handleSearchReply) 
    },[])

    const calcTotal = rows => {
        let sum = 0
        rows.forEach(it => {
            if (Number.isInteger(Number(it.pris)) && Number.isInteger(Number(it.antal))) {
                sum += Number(it.pris) * Number(it.antal)
            }
        })
        return sum
    }

    const handleAdd = row => {
            const newRows = [...rows, row]
            setRows(newRows)
            setRow({})
            setTotal(calcTotal(newRows))
    }    
    const handleChange = e => {
        setRow({...row, [e.target.name]:e.target.value})
    } 
    const handleDelete = index => {
        const newRows = rows.filter((it, ix) => ix !==index)
        setTotal(calcTotal(newRows))
        setRows(newRows)
    } 
    const handleChangeIndex = (e, index) => {
        const newRows = rows.map((row, ix) => {
            if (ix === index) {
                return {...row, [e.target.name]:e.target.value}
            } else {
                return row
            }
        })
        setTotal(calcTotal(newRows))
        setRows(newRows)
    }    

    const handleKeyPress = (e, row) => {
        if (e.key === 'Enter') {
            handleAdd(row)
        }
    }

    const handleSaveCallback = reply => {
        const {status, record} = reply

        if (status === 'OK' && handleStatus !== undefined) {
            handleStatus({backgroundColor:'green'}, undefined)
        } else {
            //const message = 'FELMEDDELANDE: Servicerapporten kunde inte uppdateras'
            // handleStatus({backgroundColor:'red'}, message)
        }    
    }        


    const handleOnMouseLeave = () => {
        setMouseLeave(Number(mouseLeave)+1)
        if (handleStatus !== undefined) {
            handleStatus({backgroundColor:'green'}, 'Uppdaterar tabellen med komponenter i databasen')
        }    
        // Add id
        const rowsWithId = rows.map(it => ({...it, id}))
        //alert('Input:' + JSON.stringify(newRows))
        serverPost('/replaceMultipleRows', '', '', {tableName:'tbl_komponent', rows:rowsWithId}, reply=>handleSaveCallback(reply))

    }
    const total = calcTotal(rows)
    return(
        <div onMouseLeave={handleOnMouseLeave}>
            <table style={{borderCollapse:'collapse'}}>
                <tbody>
                        <td><input 
                                type='text' 
                                name={'komponent'} 
                                placeholder='Komponent'  
                                value={row.komponent?row.komponent:''} 
                                onChange={handleChange}
                                onKeyPress={e=>handleKeyPress(e, row)}
                                />
                        </td>
                        <td><input 
                                size={5}
                                type='number' 
                                name={'antal'} 
                                placeholder='Antal'  
                                value={row.antal?row.antal:''} 
                                onChange={handleChange}
                                
                                />
                        </td>
                        <td><input 
                                size={5}
                                type='number' 
                                name={'pris'} 
                                placeholder='Pris'  
                                value={row.pris?row.pris:''} 
                                onChange={handleChange}
                                onKeyPress={e=>handleKeyPress(e, row)}
                                />
                        </td>
                        <td><AddIcon onClick={e=>handleAdd(row)}/></td>
                </tbody>
            </table>    
            <br/>
            <table style={{borderCollapse:'collapse', border:0, margin:0, padding:0}}>
                <tbody>
                    {rows.length > 0?
                        rows.map((it, ix) =>
                            <tr>
                                <td><input type='text' name={'komponent'} value={it.komponent?it.komponent:undefined} onChange={e=>handleChangeIndex(e, ix)} /></td>
                                <td><input size={5} type='number' name={'antal'} value={it.antal?it.antal:undefined} onChange={e=>handleChangeIndex(e, ix)}/></td>
                                <td><input size={5} type='number' name={'pris'} value={it.pris?it.pris:undefined} onChange={e=>handleChangeIndex(e, ix)}/></td>
                                <td><DeleteIcon onClick={()=>handleDelete(ix)} /></td>
                            </tr>
                        )    
                    :
                        null
                    }
                    {total!=0?
                        <tr>
                            <td style={{}} colSpan={2}>Total</td>
                            <td>{calcTotal(rows)}</td>
                        </tr>
                    :
                        null
                    }        
                </tbody>
            </table>
        </div>
    )
}

