import React, {useState, useEffect, useRef} from 'react';
import Markdown from '../components/Markdown'

const styles = {
    container: {
        width:'80vw',
        margin:'auto',
        marginTop:80,
        overflow:'auto',
    },
    item: {
        margin:'auto',
    },
}



export default ()=> {
return(
    <div style={styles.container}>
        <Markdown />
    </div>
)
}
