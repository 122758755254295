import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import SearchField from './SearchField';

const styles = {
    form:{
        padding:50,
        border:"2px solid black", 
        background:'lightGrey'
    },
    button:{
        backgroundColor:'#F9F6EE'
    }
}

const searchFields1 = inputRef=>([
    {label:'För- och efternamn', name:'namn', autoFocus:true, style:{width:190}},
    {label:'Telefonnummer', name:'mobil', style:{width:190}},
    {},
    {},	
    {}	
])    	

const searchFields2 = [
    {label:'Inlämningsnummer fr.o.m.', width:20, labelResult: 'Inlämningsnr', name:'idFrom', style:{width:100}, arrName:'id', arrType:'from'},
    {label:'Inlämningsnummer t.o.m.', labelResult: 'Inlämningsnr', name:'idTo', style:{width:100}, arrName:'id', arrType:'to'},
    //{label:'Felbeskrivning', name:'felbeskrivning'},
    //{label:'Fabrikat', name:'fabrikat'},
    //{label:'Modell', name:'modell'},
    //{label:'Mottagare', name:'mottagare'},
    //{label:'Tekniker', name:'tekniker'},
    {label:'Mottagare', name:'mottagare', style:{width:190}},
    {label:'Inlämnat fr.o.m.', type:'date',  name:'inlamnadDateFrom', style:{width:140}, arrName:'creaTimestamp', arrType:'from'},		
    {label:'Inlämnat t.o.m.', type:'date', name:'inlamnadDateTo', style:{width:140}, arrName:'creaTimestamp', arrType:'to'},		
]    	

const searchFields3 = [
    {label:'Tekniker', name:'tekniker', style:{width:190}},	
    {label:'Fabrikat', name:'fabrikat', style:{width:190}},	
    {label:'Modell', name:'modell', style:{width:190}},	
    {label:'Kontaktad för avhämtning fr.o.m.', type:'date',  name:'kontaktadAvhamtningDateFrom', style:{width:140},	arrName:'kontaktadAvhamtning', arrType:'from'},	
    {label:'Kontaktad för avhämtning t.o.m.', type:'date', name:'kontaktadAvhamtningDateTo', style:{width:140},	arrName:'kontaktadAvhamtning', arrType:'to'},	
]    	

const searchFields4 = [
    {label:'Ej utlämnat', type:'checkbox', name:'ejUtlamnat', style:{textAlign:'left'}},
    {label:'Ej kontaktad för avhämtning', type:'checkbox', name: 'ejKontaktad', style:{textAlign:'left'}},
    {label:'Utlämnat av', name:'utlamnadAv', style:{width:190}, disabledIf:'ejUtlamnat'},
    {label:'Utlämnat fr.o.m.', type:'date',  name:'utlamnadFrom', style:{width:140}, arrName:'utlamnadDate', arrType:'from', disabledIf:'ejUtlamnat'},	
    {label:'Utlämnat t.o.m.', type:'date', name:'utlamnadTo', style:{width:140}, arrName:'utlamnadDate', arrType:'to', disabledIf:'ejUtlamnat'}	
    ]    	

const searchFields5 = [
    {label:'Garanti/Akut', type:'checkbox', name:'akutOrGaranti', style:{textAlign:'left'}},
    {},
    {},
    {},	
    {}	
]    	


const LAST_SECOND='T23:59:59'

export default props => {
    const [value, setValue] = useState({})
    const {setList, handleSearch, inputRef} = props

    const handleRensa = () => {
        setList([])
        setValue({})
    }    

    const adjustedValue = () => {
        const searchFields = [...searchFields1(inputRef), ...searchFields2, ...searchFields3, ...searchFields4, ...searchFields5]
        let adjValue =  {}
        searchFields.forEach(it => {
            if (value[it.name]) {
                if (it.arrType === 'from') {
                    const toDefault = it.type==='date'?'2100-01-01'+LAST_SECOND:999999999;
                    let arr = adjValue[it.arrName]?[value[it.name], adjValue[it.arrName][0]]:[value[it.name], toDefault]
                    adjValue = {...adjValue, [it.arrName]:arr}
                } else if (it.arrType === 'to') {
                    const fromDefault = it.type==='date'?'2000-01-01':0;
                    let arr = adjValue[it.arrName]?[adjValue[it.arrName][0], value[it.name]+LAST_SECOND]:[fromDefault, value[it.name]+LAST_SECOND]
                    adjValue = {...adjValue, [it.arrName]:arr}
                } else {
                    const val = value[it.name]
                    adjValue = {...adjValue, [it.name]:val}
                }    
            }    
        })
        return adjValue
    }    

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSearch(adjustedValue())
        }
    }
    
    return(
        <>
            <form style={styles.form}>
                <div className="columns" style={{alignItems:"flex-end", flexWrap:'wrap'}}>
                    {searchFields1(inputRef).filter(fld=> !fld.hiddenInSearch).map(fld => 
                        <div className="column">
                            <SearchField fld={fld} value={value} setValue={setValue} handleKeyPress={handleKeyPress} />
                        </div>
                    )}
                </div>
                <br/>
                <div className="columns" style={{alignItems:"flex-end", flexWrap:'wrap'}}>
                    {searchFields2.map(fld => 
                        <div className="column">
                            <SearchField fld={fld} value={value} setValue={setValue} handleKeyPress={handleKeyPress} />
                        </div>
                    )}
                </div>
                <br/>
                <div className="columns" style={{alignItems:"flex-end", flexWrap:'wrap'}}>
                    {searchFields3.map(fld => 
                        <div className="column">
                            <SearchField fld={fld} value={value} setValue={setValue} handleKeyPress={handleKeyPress} />
                        </div>
                    )}
                </div>
                <br/>
                <div className="columns" style={{alignItems:"flex-end", flexWrap:'wrap'}}>
                    {searchFields4.map(fld => 
                        <div className="column">
                            <SearchField 
                                fld={fld} 
                                value={value} 
                                setValue={setValue} 
                                disabled={value[fld.disabledIf]?true:false}
                                handleKeyPress={handleKeyPress} />
                        </div>
                    )}
                </div>
                <div className="columns" style={{alignItems:"flex-end", flexWrap:'wrap'}}>
                {searchFields5.map(fld => 
                    <div className="column">
                        <SearchField 
                            fld={fld} 
                            value={value} 
                            setValue={setValue} 
                            disabled={value[fld.disabledIf]?true:false}
                            handleKeyPress={handleKeyPress} />
                    </div>
                )}
                </div>
                <p/>
                <div style={{textAlign:'center', marginTop:20, marginBotton:20}}>
                <Button type="button" style={styles.button} color="inherit" variant="outlined" onClick={()=>handleSearch(adjustedValue())} >Sök</Button>
                &nbsp;&nbsp;
                <Button type="button" style={styles.button} color="inherit" variant="outlined" onClick={handleRensa}>Rensa</Button>
                </div>
            </form>
        </>
    )
}
/*
<div className="columns" style={{alignItems:"flex-end"}}>
{searchFields4.map(fld => 
    <div className="column">
        <SearchField 
            fld={fld} 
            value={value} 
            setValue={setValue} 
            disabled={value[fld.disabledIf]?true:false}
            handleKeyPress={handleKeyPress} />
    </div>
)}
</div>
*/
