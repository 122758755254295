
import React, {useState, useEffect} from 'react';
import RteEditor from './RteEditor'
import Tooltip from '@mui/material/Tooltip';
import {isAndroidOperatingSystem} from '../services/isAndroid'


const isAndroid = isAndroidOperatingSystem()

const SearchField = props => {
    const {fld, disabled, value, setValue, handleKeyPress} = props
    const radioValues = fld.radioValues?fld.radioValues.map(it=>it.trim()):fld.values?fld.values:undefined
    const selectValues = fld.selectValues?fld.selectValues.map(it=>it.trim()):undefined
    const tooltip=fld['tooltip']?fld['tooltip']:''
    const handleChange = e => setValue({...value, [e.target.name]:e.target.type==='checkbox'?e.target.checked:e.target.value})
    const labelStyle={fontWeight:700, ...props.labelStyle?props.labelStyle:{}}
    const fldStyle={width:300}
    return(
        <p>
            {fld.checkboxName?
                <input 
                        type={'checkbox'}
                        value={value[fld.checkboxName]?value[fld.checkboxName]:''} 
                        name={fld.checkboxName} 
                        checked={value[fld.checkboxName]==1}
                        onChange={handleChange} 
                />

            :
                null
            }    
            &nbsp;
            {fld.label?
                <label style={labelStyle}>
                        {fld.label}
                </label>    
            :
                null
            }    
            <br/>
            {fld.name?
                        <input {...fld} 
                            type={fld.type} 
                            value={value[fld.name]?value[fld.name]:''} 
                            name={fld.name} 
                            style={fld.style?fld.style:fldStyle} 
                            //placeholder={fld.label?fld.label:fld.name} 
                            onChange={handleChange} 
                            disabled={disabled?disabled:false}
                            onKeyPress={handleKeyPress}
                            autoFocus={fld.autoFocus}
                        />
            :
                null
            }    
        </p>
    )
}
/*
<Tooltip title={fld['tooltip']?fld['tooltip']:''}>
</Tooltip>
*/

export default SearchField
