import React, {useState, useRef, useEffect} from 'react';
import {flushSync} from 'react-dom';
import {useNavigate, useParams} from 'react-router-dom';
import StatusMessage from '../components/StatusMessage'
import {fetchEmployees} from '../services/serverFetch'
import serverPost from '../services/serverPost'
import {search} from '../services/search'
import {sleep} from '../services/functions'
import {EMPLOYEES} from '../services/constants'
import FormTemplate from '../components/FormTemplate'
import SearchTemplate from '../components/SearchTemplate'
import EditTable from '../components/EditTable'
import {PrintFields, PrintLapp, RenderKunduppgifter2} from '../components/PrintComponentVatansever'
import moment from 'moment'
import logo from '../images/logo.png'
import {useReactToPrint} from 'react-to-print'
import { useSharedState } from '../store';

const tableCustomer = 'tbl_customer'
const tableSR = 'tbl_service'
const searchView = 'view_service'



const styles = {
    container: {
        marginTop:80,
        display:'flex',
        flexDirection:'column',
        width:'100vw',
        overflow:'auto',
    },
    item: {
        margin:'auto',
    },
}

const MODE = {
    SEARCH:1,
    NEW:2
}

const autoFocusIfMottagareSet = value => {
    return(value['mottagare']?true:undefined)
}

const formFieldsFunc = emp => ([
    {
        type:'radio',
        radioValues:emp,
        label:'Mottagare',
        name:'mottagare',
        tooltip:'Mottagare av objektet framme vid disken',
        required:true,
        hiddenIf:'annanMottagare'
    },
    {
        type:'text',
        label:'Mottagare',
        name:'mottagare',
        tooltip:'Om mottagaren är annan person än Okhan, Johannes eller MatsFabrikat på inlämnad apparat',
        required:true,
        notHiddenIf:'annanMottagare',
        maxlength:79,
    },
    {
        type:'checkbox',
        label:'Annan',
        name:'annanMottagare',
        tooltip:'Om mottagaren är annan person än dem som det finns radioknapp till ovan',
    },
    {
        type:'text',
        label:'Fabrikat',
        name:'fabrikat',
        required:true,
        tooltip:'Fabrikat på inlämnad apparat',
        maxlength:79,
        // autoFocusIfValueSet:autoFocusIfMottagareSet
        // autoFocus:true,
    },
    {
        type:'text',
        label:'Modell',
        name:'modell',
        required:true,
        tooltip:'Modell på inlämnad apparat',
        maxlength:79,
    },
    {
        type:'checkbox',
        label:'Akut prioritet',
        name:'akutPrioritet',
        tooltip:'Om objektet måste repreras supersnabbt till dyrare kostad',
    },
    {
        type:'checkbox',
        label:'Felsökning',
        name:'felsokning',
        tooltip:'Teknikern skall först göra felsokning och ge besked till kund',
    },
    {
        type:'checkbox',
        label:'Garanti',
        name:'garanti',
        tooltip:'Om reparationen går på garanti',
    },
    {
        type:'TextArea',
        label:'Felbeskrivning',
        name:'felbeskrivning',
        cols:40,
        minRows:5,
        maxRows:50,
        required:true,
        tooltip:'Beskrivning av felet',
        maxlength:4999,
    },
    /*
    {
        type:'number',
        label:'Antal apparatlappar',
        name:'numberOfLapp',
        required:true,
    },
    */
])

const extraFields = [
    {
        type:'text',
        label:'Namn',
        name:'namn',
        tooltip:'Kundens namn',
    },
    {
        type:'text',
        label:'Telefon',
        name:'mobil',
    },
    {
        type:'email',
        label:'Telefon',
        name:'email',
    },
    {
        type:'number',
        label:'id',
        name:'Inlämningsnummer',
        notHiddenIf:'id',
    },
]

const fieldsLapp = [
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunduppgifter',
    },
    {
        label:'Namn',
        name:'namn',
    },
    {
        label:'Telefonnummer',
        name:'mobil',
    },
    {
        label:'E-mail',
        name:'email',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Inlämnat objekt',
    },
    {
        label:'Fabrikat',
        name:'fabrikat',
    },
    {
        label:'Modell',
        name:'modell',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Vi tar kontakt efter felsökning',
        name:'felsokning',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Garantiärende',
        name:'garanti',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Reparationen utförs akut mot extra avgift',
        name:'akutPrioritet',
    },
    {
        emptyRow:true,
    },
    {
        ignoreValue:true,
        label:'Felbeskrivning',
        name:'felbeskrivning',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        name:'felbeskrivning',
        valueStyle:{maxWidth:265, wordWrap:'normal', overflowWrap: 'anywhere'},
        maxlength:4999,
    },
    {
        emptyRow:true,
    },

]

const fieldsKvitto = [
    {
        emptyRow:true,
    },
    {
        label:'Inlämningsnr.',
        name:'id'
    },
    {
        dateAndTime:true
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunduppgifter',
    },
    {
        label:'Namn',
        name:'namn',
    },
    {
        label:'Telefonnummer',
        name:'mobil',
    },
    {
        label:'E-mail',
        name:'email',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Inlämnat objekt',
    },
    {
        label:'Fabrikat',
        name:'fabrikat',
    },
    {
        label:'Modell',
        name:'modell',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Vi tar kontakt efter felsökning',
        name:'felsokning',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Garantiärende',
        name:'garanti',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Reparationen utförs akut mot extra avgift',
        name:'akutPrioritet',
    },
    {
        emptyRow:true,
    },
    {
        ignoreValue:true,
        label:'Felbeskrivning',
        name:'felbeskrivning',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        name:'felbeskrivning',
        valueStyle:{maxWidth:265, wordWrap:'normal', overflowWrap: 'anywhere'}
    },
    {
        emptyRow:true,
    },

]

const searchFields = [
    {label:'Inlämningsnummer', name:'id'},
    {label:'För- och efternamn', name:'namn'},
    {label:'Telefonnummer', name:'mobil'},	
    {label:'E-mail', name:'email'},	
]    	

const resultFields = [
    {label:'Nr', name:'id'},
    {label:'Namn', name:'namn'},
    {label:'Telefon', name:'mobil'},	
    {label:'E-mail', name:'email'},	
    {label:'Fabrikat', name:'fabrikat'},	
    {label:'Modell', name:'modell'},	
    {label:'Utlämnad', name:'utlamnadDate'},	
]    	

moment.locale('sv', {week:{dow : 1}})

const textAppBar = "Inlämning - Inlämnat objekt"

export default () => {

    const params = useParams()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [value, setValue] = useState(params?params:{})
    const [statusMessage, setStatusMessage] = useState({color:'green', message:''})
    const [flerObjekt, setFlerObjekt]=useState(false)
    const componentRefKvitto = useRef();
    const componentRefLapp = useRef();
    const onBeforeGetContentResolve = useRef();
    const [global, setGlobal] = useSharedState();
    const [formFields, setFormFields] = useState(formFieldsFunc(EMPLOYEES))

    const cntLapp = 0

    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
        let timer = setTimeout(() => setStatusMessage({}), 5000);
    }        

    useEffect(()=>{
        setGlobal({...global, pageName:(params.kundId > 0)?textAppBar:textAppBar})
        if ((params.kundId === 'latest') || (params.kundId===undefined)) {
            // Search service report
            setGlobal({...global, pageName:textAppBar})
            search(searchView, {}, handleSearchReplySR)
        } else if (params.kundId !== undefined) { 
            // Search customer
            setGlobal({...global, pageName:textAppBar})
            search(tableCustomer, {id:params.kundId}, handleSearchReplyCustomer)
        } 
        fetchEmployees(setFormFields, formFieldsFunc)
    }, [params.kundId])


    const handleSaveAndPrintCallback = reply => {
        const {status, id} = reply

        if (status === 'OK') {
            // alert('Save callback reply:' + JSON.stringify(reply))
            setValue({...value, id:id})
            // Sleep 500 ms to ensure that value is set with new id before  
            sleep(500).then(()=>printLappOchKvitto())
            //handleStatus({backgroundColor:'green'}, 'Servicerapport med id = ' + id + ' är sparad')
        } else {
            const message = 'FELMEDDELANDE: Servicerapporten kunde inte uppdateras'
            handleStatus({backgroundColor:'red'}, message)
        }    
    }        

    const handleSaveAndPrint = () => {
        // handleStatus({backgroundColor:'green'}, 'Uppdaterar databasen ...')
        if (!!value.mottagare && !! value.fabrikat && !!value.modell || !!value.felbeskrivning) {
            serverPost('/replaceRow', '', '', {tableName:tableSR, record:value, kundId:value.kundId}, handleSaveAndPrintCallback)
        } else {
            alert('FELMEDDELANDE: Försöker sätta in en record som saknar nödvändig input:' + JSON.stringify(value))
        }    
    }

    const handleClickLine = rec => {
        setGlobal({...global, pageName:textAppBar})
        setValue({...value, ...rec})
    }
    
    const handleSearchReplyCustomer = list => {
        if (list.length === 0) {
            setValue({})
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i database')    
        } else if (list.length === 1) {
            const val = list[0]
            setValue({...val, kundId:val.id, id:undefined}) // Set kundId to id and id as undefined
            setList([])
            setGlobal({...global, pageName:textAppBar})
        } else {
            setGlobal({...global, pageName:textAppBar})
            setList([])
        }    
    }

    const handleSearchReplySR = list => {
        if (list.length === 0) {
            setValue({})
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i database')    
        } else if (list.length === 1 || params.kundId === 'latest') {
            const val = list[0]
            setValue(val)
            setGlobal({...global, pageName:'Inlämning - Inlämnat objekt'})
        } else {
            setGlobal({...global, pageName:'Inlämning - SOK inlämnat objekt'})
            setList(list)
        }    
    }

    const handleSearch = searchFields => {
        // alert('value:' + JSON.stringify(value))
        search(searchView, searchFields, handleSearchReplySR) 
    }

    const handleReset = () => {
        setValue({
            id:value.id, 
            namn:value.namn, 
            mobil:value.mobil, 
            email:value.email, 
            fakturaKund:value.fakturaKund, 
            orgNr:value.orgNr
        })
        setList([])
    }

   
    /*
    const handlePrintLapp = useReactToPrint({
        content: () => componentRefLapp.current,
        onAfterPrint: () => {
            handleSave(value)   
        }    
    });
    */ 

    const handlePrintKvitto = useReactToPrint({
        content: () => componentRefKvitto.current,
        onAfterPrint: () => {
            if (flerObjekt===true) {
                const kunduppgifter = {
                    kundId:value.kundId,
                    namn:value.namn,
                    mobil:value.mobil,
                    email:value.email,
                    oriNr:value.orgNr, 
                    falturaKund:value.fakturaKund
                }
                setValue({...kunduppgifter, mottagare:value.mottagare})
            } else {
                navigate('/home')
            }    
        }    
    });

    const printLappOchKvitto = useReactToPrint({
        content: () => componentRefLapp.current,
        onAfterPrint: handlePrintKvitto
    });

    const handleAvbryt =  () => {
        let text;
        // eslint-disable-next-line no-restricted-globals
        navigate('/home')
    }

   
    const buttons=[
        {
            style:{color:'black', borderColor:'black'},
            label:'Skriv ut',
            required:true,
            handleClick:handleSaveAndPrint
        },    
        /*
        {
            style:{color:'black', borderColor:'black'},
            label:'Rensa',
            handleClick:handleReset
        },
        */    
        {
            style:{color:'black', borderColor:'black'},
            label:'Avbryt',
            disabled: value.tekniker || value.atgarder ||value.material?true:undefined,
            handleClick:handleAvbryt
        },    
    ]

    return(    
        <>
                <PrintLapp  ref={componentRefLapp} value={value} flerObjekt={flerObjekt} fields={fieldsLapp} />
                <PrintFields ref={componentRefKvitto} value={value} fields={fieldsKvitto} />
                <p/>

                {value.kundId && value.kundId !== 'latest'?
                    <div style={styles.container}>
                        <div style={styles.item}>
                            <RenderKunduppgifter2 
                                value={value} 
                                anchor={true} 
                                flerObjekt={flerObjekt}    
                                handleFlerObjekt={e=>setFlerObjekt(e.target.checked)}
                                showId={value.id?true:false}
                            />

                            <FormTemplate
                                tableSR={tableSR} 
                                fields={formFields} 
                                value={value} 
                                setValue={setValue}
                                handleStatus={handleStatus}  
                                buttons={buttons}
                            />
                        </div>
                    </div>
                :
                    <div style={styles.container}>
                        {list.length===0?
                            <div style={styles.item}>
                                <SearchTemplate 
                                    searchView={searchView}
                                    searchFields={searchFields}
                                    setValue={setValue} 
                                    setList={setList} 
                                    handleSearch={searchFields => search(searchView, searchFields, handleSearchReplySR)} 
                                    handleStatus={handleStatus}
                                />
                            </div>    
                        :
                            <div style={styles.item}>
                                <EditTable 
                                    tableSR={tableSR}
                                    resultFields={resultFields}
                                    list={list} 
                                    setList={setList} 
                                    handleStatus={handleStatus}  
                                    handleClickLine={handleClickLine}
                                />
                            </div>    
                        }
                    </div>
                 }
                <p/>
                <StatusMessage style={statusMessage.style} message={statusMessage.message} />
        </>    
    )
}

