
import React, {useState, useEffect, useRef} from 'react'
import 'bulma/css/bulma.min.css'
import logo from '../images/logo.png'


const valueTest = {
    namn: 'Per Eskilson',
    mobil:'0733-780749',
    email:'paelsis@hotmail.com',
    orgNr:'551234-2019',

    inlamnatObject: 'Sony TC360',
    modell:'TC360',
    felbeskrivning:'Ena kanalen i RIAA tyst Skräp i reglage och brytare', 
    atgarder: 'Reparerat RIAA är tyst. Bytt defekta komponenter\n\
Rengjort reglage och brytare\n\
Funktionskontroll',
    material: '1 lampa\n\
1 motstånd\n\
1 konding',
}

const styles = {
    pre:{
        border:'1px solid black',
    }
}

const classPre = 'field box-color p-1 mr-5 pt-0 is-size-6 is-family-sans-serif'
const stylePre = {border:'1px solid black'}

export default () => {
    const value = valueTest
    return(    
        <div style={{paddingTop:70, marginRight:70, marginLeft:100}}> 
                <div class='columns'>
                    <div class='is-hidden-tablet pr-1 column'>
                            <img src={logo} alt='Vatansever Image' />
                        </div>
                        <div class='pl-5 pt-5 column is-7'>
                            <p class="is-size-4">Vatansever Ljud AB</p>
                            <p>Spånehusvägen 88</p>
                            <p>Tel: 076-005 40 99</p>
                            <p>Org.nr: 559323-9279</p>
                        </div>
                        <div class='is-hidden-mobile pr-1 column' >
                            <img src={logo} alt='Vatansever Image' />
                        </div>
                </div>     
                <div class='columns'>
                        <div class='pl-5 column is-7' >
                            <p class="is-size-5">Kunduppgifter</p>
                            <p>Namn: {value.namn}</p>
                            <p>Tel: 076-005 40 99</p>
                            <p>Org.nr: 559323-9279</p>
                        </div>
                        <div class='column'>
                            <pre class={classPre} style={styles.pre}>
                                <p>Inlämnad:{value.inlamnadDate}</p>
                                <p>Utlämnad:{value.utlamnadDate}</p>
                                <p>Tekniker:{value.tekiker}</p>
                                <p>Utlämnad:{value.utlamnadAv}</p>
                            </pre>
                        </div>
                </div>     
                    <div class='pt-0 pl-4'>
                        <p class="is-size-5">Felbeskrivning:</p>
                        <pre class={classPre} style={styles.pre}>{value.felbeskrivning}</pre>
                    </div>
                    <div class='pl-4'>
                        <p class="is-size-5">Utförda åtgärder:</p>
                        <pre class={classPre} style={styles.pre}>{value.atgarder}</pre>
                    </div>
                    <div class='table pl-4'>
                        <p class="is-size-5">Material</p>
                        <pre class={classPre} style={styles.pre}>{value.material}</pre>
                    </div>
        </div>
        )
}