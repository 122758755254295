
import React, {useState, useEffect} from 'react';
import serverPost from '../services/serverPost'
import {search} from '../services/search'
import Calculator from '../components/Calculator'
import StatusMessage from '../components/StatusMessage'


export default () => {
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(0)
    const [statusMessage, setStatusMessage] = useState({})
    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
    }
    const id=12

    const handleReply = (val) => {
        if (val.status === 'ERROR') {
            alert(JSON.stringify(val))
        } else {
            handleStatus({backgroundColor:'green'}, 'Successful read !') 
        }    
    }
    const handleSaveRows = () =>{
        const newRows = rows.map(it => ({...it, id}))
        //alert('Input:' + JSON.stringify(newRows))
        serverPost('/replaceMultipleRows', '', '', {tableName:'tbl_komponent', rows:newRows}, val=>handleReply(val))
    }    

    return(
        <>
            <Calculator tableName={'tbl_komponent'} id={id} setTotal={setTotal} rows={rows} setRows={setRows} handleStatus={handleStatus} />
            <button onClick={handleSaveRows}>Save</button>
            <StatusMessage style={statusMessage.style} message={statusMessage.message} />

            Total:{total}
        </>

    )
}



//<div style = {{...styles.img, backgroundImage: `url(${Image})`}} />
