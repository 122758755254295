import serverFetch from './serverFetch';
import serverPost from './serverPost';

// search and pass a list of objects in the callack function handleReply
export const search = (tableName, value, handleReply) =>
{
    const searchValues = value?value:{}
    let link = ''
    let args =""
    Object.entries(searchValues).map(it=> {
        if (it[1]) {
            args += '&'
            args += it[0] +  '=' + it[1]
        }
    })
    if (tableName) {
        link = '/fetchRows?tableName=' + tableName + args
    } else {
        alert('No table name')
    }    
    //alert('search link:' + JSON.stringify(link))
    serverFetch(link, '', '', list=>{
        //alert('search data:' + JSON.stringify(list))
        if (list.length !== 0) {
            if (!!handleReply) {
                handleReply(list)
            }    
        } else {
            handleReply([])
        }   
    })
}        

export const search2 = (tableName, value, handleReply) =>
{
    const link = '/fetchRows2'
    const data = {
        tableName,
        value, 
    }
    //alert('search2 data:' + JSON.stringify(data))
    serverPost(link, '', '', data, reply=>{
        const list = reply.result
        //alert('search2 result list=' + JSON.stringify(list))
        if (reply.status === 'WARNING') {
            alert(reply.message?reply.message:'WARNING: The funciton search2 returned wiht a status WARNING')
        }
        if (list.length !== 0) {
            if (!!handleReply) {
                handleReply(list)
            }    
        } else {
            handleReply([])
        }   
    })
}        
