
import React, {useState, useEffect, useRef} from 'react'
import 'bulma/css/bulma.min.css'
import logo from '../images/logo.png'
import moment from 'moment'
import { CompanyHeader } from './PrintComponentVatansever'
import {defaultDate} from '../services/functions'

const valueTest = {
    namn: 'Per Eskilson',
    mobil:'0733-780749',
    email:'paelsis@hotmail.com',
    orgNr:'551234-2019',

    tekniker:'Okhan',
    inlamnatObject: 'Sony TC360',
    modell:'TC360',
    felbeskrivning:'Ena kanalen i RIAA tyst Skräp i reglage och brytare', 
    atgarder: 'Reparerat RIAA är tyst. Bytt defekta komponenter\n\
Rengjort reglage och brytare\n\
Funktionskontroll',
    material: '1 lampa\n\
1 motstånd\n\
1 konding',
}

const styles = {
    pre:{
        //fontFamily:'Regular',
        border:'1px solid black',
    }
}

const classPre = 'field box-color p-4 mr-5 pt-0 is-size-6 is-family-sans-serif'
const classLabel = 'is-size-6 has-text-weight-semibold'
const stylePre = {border:'1px solid black'}

export default props => {
    const value = props.value?props.value:valueTest
    const now = defaultDate()
    const utlamnadDate = value.utlamnadAv?value.utlamnadDate?value.utlamnadDate:now:null
    return(   
        <div style={{paddingTop:70, marginLeft:80, marginRight:60}}> 
                    <div class='columns pl-4'>
                            <div class='is-hidden-tablet column'>
                                <img src={logo} alt='Vatansever Image' />
                            </div>
                            <div class='column is-6 pl-4 mt-5 is-size-5'>
                                <CompanyHeader />
                            </div>
                            <div class='is-hidden-mobile column' >
                                <img src={logo} alt='Vatansever Image' />
                            </div>
                    </div>     
                    <div class='columns pl-4' style={{alignItems:'flex-end'}}>
                            <div class='column is-7'>
                                <p class={classLabel}>Kunduppgifter</p>
                                <pre class={classPre} style={styles.pre}>
                                <table>
                                    <tr>
                                        <td>Namn:&nbsp;&nbsp;</td>    
                                        <td>{value.namn}</td>
                                    </tr>
                                    <tr>
                                        <td>Tel:&nbsp;&nbsp;</td>    
                                        <td>{value.mobil}</td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:&nbsp;&nbsp;</td>    
                                        <td>{value.email}</td>
                                    </tr>
                                    {value.orgNr?
                                        <tr>
                                            <td>Org.nr.:&nbsp;&nbsp;</td>    
                                            <td>{value.orgNr}</td>
                                        </tr>
                                    :
                                        null
                                    }
                                </table>
                                </pre>
                            </div>
                            <div class='column'>
                                <p class="is-size-5 has-text-weight-semibold">Servicerapport</p>
                                <p class="is-size-6 has-text-weight-semibold">Inlämningsnummer:&nbsp;&nbsp;{value.id}</p>
                                <pre class={classPre} style={styles.pre}>
                                    <table>
                                        <tr>
                                            <td>Inlämnad:&nbsp;&nbsp;</td>    
                                            <td>{value.creaTimestamp?value.creaTimestamp.substring(0,10):null}</td>
                                        </tr>
                                        <tr>
                                            <td>Utlämnad:&nbsp;&nbsp;</td>    
                                            <td>{utlamnadDate?utlamnadDate:null}</td>
                                        </tr>
                                        <tr>
                                            <td>Tekniker:&nbsp;&nbsp;</td>    
                                            <td>{value.tekniker}</td>
                                        </tr>
                                        <tr>
                                            <td style={{whiteSpace:'no-wrap', width:100}}>Utlämnad av:</td>    
                                            <td >{value.utlamnadAv}</td>
                                        </tr>
                                    </table>
                                </pre>
                            </div>
                    </div>     
                    <div class='pt-0 pl-4'>
                        <p class={classLabel}>Inlämnat objekt</p>
                        <pre class={classPre} style={styles.pre}>
                            <table> 
                                    <tr>
                                        <td>Fabrikat:&nbsp;&nbsp;</td>    
                                        <td>{value.fabrikat}</td>
                                    </tr>
                                    <tr>
                                        <td>Modell:&nbsp;&nbsp;</td>    
                                        <td>{value.modell}</td>
                                    </tr>
                            </table>
                        </pre>
                    </div>
                    <div class='pt-0 pl-4'>
                        <p class={classLabel}>Felbeskrivning</p>
                        <pre class={classPre} style={styles.pre}>{value.felbeskrivning}</pre>
                    </div>
                    <div class='pt-0 pl-4'>
                        <p class={classLabel}>Utförda åtgärder</p>
                        <pre class={classPre} style={styles.pre}>{value.atgarder}</pre>
                    </div>
                    <div class='pt-0 pl-4'>
                        <p class={classLabel}>Använt material</p>
                        <pre class={classPre} style={styles.pre}>{value.material}</pre>
                    </div>
            </div>
    )        
}