import React, {useState, useEffect, useRef} from 'react';
import StatusMessage from '../components/StatusMessage'
import {useNavigate, useParams } from 'react-router-dom';
import {search2} from '../services/search'
import SearchVatansever from '../components/SearchVatansever'
import EditTable from '../components/EditTable'
import { useSharedState } from '../store';
import TextArea from "react-textarea-auto-resize";


const styles = {
    container: {
        marginTop:80,
        display:'flex',
        flexDirection:'column',
        width:'100vw',
        overflow:'auto',
    },
    item: {
        margin:'auto',
    },
    flexContainer:{
        display:'flex',
        paddingTop:20,
        flexDirection: 'row',
        flexWrap:'wrap',  
        //backgroundColor:'rgba(32,32,32,1)',
        alignItems: 'flex-start',
        //justifyContent: 'top',
        //color:'#FFFFFF',
        // height:'calc(100vh \- 50px)'
    },
    row1:{
        flex:1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight:100

    },
    row2:{
        flex:2,
        maxWidth:500,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',       
        wordWrap: 'break-word',
    },
    button:{
        marginLeft:'5px',
        marginRight:'5px',
        marginBottom:'10px',
        borderWidth:'2px',
        height:50,
        color:'#FFFFFF',
        borderColor:'#FFFF87',
        backgroundColor:'transparent'
    }

}

    /*
    {
        type:'comment',
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kontaktad för avhämtning',
        name:'kontaktadAvhamtning',
    },
    {
        type:'comment',
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunden har kontaktats för avhämtning via',
        name:'kontaktadVia',
    },
    */

const disabledFunc1 = value => {    
    const kontaktad = value.kontaktadAvhamtningAv?value.kontaktadAvhamtningAv.length > 0:false
    return value.disabledSaveSr?(value.disabledSaveSr == 1 || kontaktad):false
}

const disabledFunc1a = value => {    
    let disabled = value.material?true:false
    return disabled || disabledFunc1(value)   

}


const disabledFunc2 = value => {    
    let requiredList = value.ingetMaterial?['tekniker', 'atgarder', 'arbetstid', 'kostnad']:['tekniker', 'atgarder', 'material', 'arbetstid', 'materialkostnad', 'kostnad']
    if (value.felsokning) {
        requiredList = [...requiredList, 'acceptVia', 'acceptInfo']
    }    
    let disabled = false
    requiredList.forEach(name => {
        if (value[name]===undefined || value[name].length === 0) {
            disabled = true
        }
    })
    return disabled || disabledFunc1(value)   
}

const disabledFunc3 = value => {    
    const disabled = value['acceptVia']?false:true
    return disabled || disabledFunc1(value)
}

const preSetValue = val => {
    return {...val, materialkostnad:val.ingetMaterial?'':val.materialkostnad, material:val.ingetMaterial?'':val.material}
}


const formFields = [
    {
        type:'radio',
        radioValues:['Okhan', 'Johannes', 'Mats'],
        label:'Tekniker',
        name:'tekniker',
        tooltip:'Mottagare av objektet framme vid disken',
        hiddenIf:'annanPerson1',
        disabledFunc:disabledFunc1,
    },
    {
        type:'text',
        label:'Tekniker',
        name:'tekniker',
        notHiddenIf:'annanPerson1',
        disabledFunc:disabledFunc1,
    },
    {
        type:'checkbox',
        label:'Annan',
        name:'annanPerson1',
        tooltip:'Kryssa i denna om teknikern är en annan person än de som det finns radio-knappar till',
        disabledFunc:disabledFunc1,
    },
    {
        type:'date',
        label:'Datum då kunden accepterat reparation',
        name:'acceptDate',
        tooltip:'Datum då kunden accepterat reparation',
        useDefaultDate:true,
        notHiddenIf:'felsokning',
        disabledFunc:disabledFunc1,
    },
    {
        type:'radio',
        label:'Accept via',
        name:'acceptVia',
        radioValues:['SMS', 'MUNTLIGT', 'MAIL'],
        tooltip:'Kunden har kontaktats via denna metod',
        notHiddenIf:'felsokning',
        disabledFunc:disabledFunc1,
    },        
    {
        type:'textarea',
        label:'Kunden har accepterat följande',
        name:'acceptInfo',
        tooltip:'Om kunden begärt felsökning måste man ange antal timmar eller något annat',
        notHiddenIf:'felsokning',
        disabledFunc:disabledFunc3,
    },
    {
        type:'textarea',
        label:'Utförda åtgärder',
        name:'atgarder',
        tooltip:'Utförda åtgärder',
        disabledFunc:disabledFunc1,
    },
    {
        type:'checkbox',
        label:'Inget material',
        name:'ingetMaterial',
        tooltip:'Kryssa i denna om du inte använt något material',
        disabledFunc:disabledFunc1a,
        preSetValue
    },
    {
        type:'textarea',
        label:'Använt material',
        name:'material',
        tooltip:'Använt marterial vid reparation',
        disabledFunc:disabledFunc1,
        hiddenIf:'ingetMaterial',
    },
    {
        type:'number',
        step:0.5,
        label:'Arbetstid (h)',
        name:'arbetstid',
        tooltip:'Den totala arbetstid som gått åt vid reparationen',
        disabledFunc:disabledFunc1,
    },
    {
        type:'number',
        label:'Materialkostnad (SEK)',
        name:'materialkostnad',
        tooltip:'Kostnad för material',
        disabledFunc:disabledFunc1,
        hiddenIf:'ingetMaterial',
        removedIf:'ingetMaterial'
    },
    {
        type:'number',
        label:'Kostnad (SEK)',
        name:'kostnad',
        tooltip:'Totala kostnaden inklusive material',
        disabledFunc:disabledFunc1,
    },
    {
        type:'radio',
        radioValues:['Okhan', 'Johannes', 'Mats'],
        label:'Kontaktad för avhämting av',
        name:'kontaktadAvhamtningAv',
        tooltip:'Namn på personen som lämnar kontaktat för avhämtning',
        hiddenIf:'annanPerson2',
        disabledFunc:disabledFunc2,
    },
    {
        type:'text',
        label:'Kontaktad för avhämting av',
        name:'kontaktadAvhamtningAv',
        tooltip:'Namn på personen som lämnar tillbaka objektet efter reparation eller kostnadsförslag',
        notHiddenIf:'annanPerson2',
        disabledFunc:disabledFunc2,
    },

    {
        type:'checkbox',
        label:'Annan',
        name:'annanPerson2',
        tooltip:'Om den som utämnar reparerat gods är en annnan person än de 3 ovan nämnda',
        labelStyle:{fontWeight:100},
        disabledFunc:disabledFunc2,
    },
    {
        type:'date',
        label:'Kontaktad för avhämtning',
        name:'kontaktadAvhamtning',
        tooltip:'Kunden har kontaktats för avhämtning',
        notHiddenIf:'kontaktadAvhamtningAv',
        useDefaultDate:true,
        disabledFunc:disabledFunc2,
    },
    {
        type:'radio',
        radioValues:['SMS', 'MUNTLIGT', 'MAIL'],
        label:'Kunden har kontaktats för avhämtning via',
        name:'kontaktadVia',
        tooltip:'Kunden har kontaktats för avhämtning via',
        notHiddenIf:'kontaktadAvhamtningAv',
        required:true,
        disabledFunc:disabledFunc2,
    },
]


const tableName = 'tbl_service'
const searchView = 'view_service'

const searchFields = [
    {label:'Inlämningsnummer', labelResult: 'Inlämningsnr', name:'id'},
    {label:'För- och efternamn', name:'namn'},
    {label:'Telefon', name:'mobil'},	
    {label:'E-mail', name:'email'},	
    {label:'Fabrikat', name:'fabrikat', hiddenInSearch:true},	
    {label:'Modell', name:'modell', hiddenInSearch:true},	
    {label:'Utlämnad', name:'utlamnadDate', hiddenInSearch:true},	
]    	

const resultFields = [
    {label:'Nr', name:'id'},
    {label:'Namn', name:'namn'},
    {label:'Telefon', name:'mobil'},
    {label:'Mottagare', name:'mottagare'},
    {label:'Felbeskrivning', name:'felbeskrivning'},
    {label:'Inlämnad', name:'creaTimestamp'},
    {label:'Tekniker', name:'tekniker'},
    {label:'Fabrikat', name:'fabrikat'},
    {label:'Modell', name:'modell'},
    {label:'Arbetstid', name:'arbetstid'},
    {label:'Kostnad', name:'kostnad'},	
    {label:'Kontaktad för avhämtning', name:'kontaktadAvhamtning'},	
    {label:'Utlämnad av', name:'utlamnadAv'},	
    {label:'Utlämnad', name:'utlamnadDate'},	
    {label:'Akut', name:'akutPrioritet', boolText:'AKUT', style:{color:'red', fontWeight:900}},	
    {label:'Garanti', name:'garanti', boolText:'GARANTI', style:{color:'red', fontWeight:900}},	
]    	

const fieldsExtra = [
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'AKUT PRIORITET',
        name:'akutPrioritet',
        labelStyle:{fontWeight:700, color:'red'}
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'GARANTI',
        name:'garanti',
        labelStyle:{fontWeight:700, color:'red'}
    },
    {
        label:'Inlämningsnummer',
        name:'id',
    },
    {
        label:'Utlämnad',
        name:'utlamnadDate',
    },
    {
        label:'Fabrikat',
        name:'fabrikat',
    },
    {
        label:'Modell',
        name:'modell',
    },
    {
        type:'pre',
        label:'Felbeskrivning',
        name:'felbeskrivning',
    },
]    

export const fieldsServiceRapport = [
    {
        emptyRow:true,
    },
    {
        label:'Inlämningsnr.',
        name:'id'
    },
    {
        label:'Utlämnad',
        name:'utlamnadDate'
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'SERVICERAPPORT',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Kunduppgifter',
    },
    {
        label:'Namn',
        name:'namn',
    },
    {
        label:'Telefonnummer',
        name:'mobil',
    },
    {
        label:'E-mail',
        name:'email',
    },
    {
        emptyRow:true,
    },
    {
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Inlämnat objekt',

    },
    {
        label:'Fabrikat',
        name:'fabrikat',
    },
    {
        label:'Modell',
        name:'modell',
    },
    {
        ignoreIfValueEmpty:true,
        ignoreSemicolon:true,
        ignoreValue:true,
        label:'Garantiärende',
        name:'garanti',
    },
    {
        emptyRow:true,
    },
    {
        ignoreValue:true,
        label:'Felbeskrivning',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'felbeskrivning',
        valueStyle:{maxWidth:300}
    },
    {
        emptyRow:true,
    },
    {
        label:'Tekniker',
        name:'tekniker',
    },
    {
        ignoreValue:true,
        label:'Utförda åtgärder',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'atgarder',
        valueStyle:{maxWidth:300}
    },
    {
        ignoreValue:true,
        label:'Använt material',
    },
    {
        type:'textarea',
        ignoreLabel:true,
        name:'material',
        valueStyle:{maxWidth:300}
    },
    {
        emptyRow:true,
    },
]

export default () => {
    const params = useParams()
    //const navigate = useNavigate()
    const [list, setList] = useState([])
    const [value, setValue] = useState({})
    const [statusMessage, setStatusMessage] = useState({})
    const [global, setGlobal] = useSharedState({});

    const inputRef = useRef(null)
    const navigate = useNavigate()
    useEffect(()=>{
        setGlobal({...global, pageName:'Sökning'})
        inputRef.current.focus()
        setList([])
    }, []) 

    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
    }

    const handleClickLine = rec => {
        setGlobal({...global, pageName:'Servicerapport - Inlämnat objekt'})
        navigate('/sr/' + rec.id)
    } 

    const handleSearchReply = list => {
        if (list.length === 0) {
            // handleStatus({backgroundColor:'transparent'}, 'Inga rader hittades')    
        } else {
            // handleStatus({backgroundColor:'green'}, undefined)    
            setGlobal({...global, pageName:'Sökning'})
            setList(list)
        }    
    }

    const handleAdvancedSearch = searchKeys => {
        setList([])
        handleStatus({backgroundColor:'green'}, 'Söker ...')    
        search2(searchView, searchKeys, handleSearchReply) 
    }

    const searchFieldsRef = searchFields.map((it, index) => index===0?{...it, inputRef}:it)
    // {JSON.stringify(value)}
    return(
            <div style={styles.container}>
                <div style={styles.item}>
                    <SearchVatansever 
                        inputRef={inputRef}
                        searchView={searchView}
                        searchFields={searchFieldsRef}
                        setValue={setValue} 
                        setList={setList} 
                        handleSearch={handleAdvancedSearch}
                        handleStatus={handleStatus}
                    />
                </div>
                <div style={styles.item}>
                    <EditTable 
                        tableName={tableName}
                        searchView={searchView} 
                        resultFields={resultFields}
                        list={list} 
                        setList={setList} 
                        handleStatus={handleStatus}  
                        handleClickLine={handleClickLine}
                    />
                </div>
                <StatusMessage timeout={2000} style={statusMessage.style} message={statusMessage.message} />
            </div>
    )
} 