import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"
import mdFile from '../docs/test.md'
//import '../index.tailwind.css'
// Markdown
export default () => {
  const [content, setContent] = useState("##Hello")


  useEffect(() => {
    fetch(mdFile)
      .then((res) => res.text())
      .then((text) => {
        // alert(text)
        setContent(text);
      })  
  }, []);

  return (
    <div className="post">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]} 
        children={content} 
      /> 
    </div>
  );
};


