import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SharedStateProvider} from './store';
import Home from "./pages/Home";
import Installningar from "./pages/Installningar";
import AppBar from "./components/AppBar";
import Kunduppgifter from "./pages/Kunduppgifter";
import Inlamning from "./pages/Inlamning";
import ServiceRapport from "./pages/ServiceRapport";
import Utlamning from "./pages/Utlamning";
import Calc from './pages/Calc'
import Search from "./pages/Search";
import Dokumentation from "./pages/Dokumentation";
import Test from './pages/Test'
import NotFound from './pages/NotFound'
import FirebaseAuth from './login/FirebaseAuth'
import FirebaseSignin from './login/FirebaseSignin';
import FirebaseResetPassword from './login/FirebaseResetPassword';
import Button from '@mui/material/Button';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import './fonts/Roboto-Black.ttf'
import './fonts/Roboto-BlackItalic.ttf'
import './fonts/Roboto-Bold.ttf'
import './fonts/Roboto-BoldItalic.ttf'
import './fonts/Roboto-Italic.ttf'
import './fonts/Roboto-Light.ttf'
import './fonts/Roboto-LightItalic.ttf'
import './fonts/Roboto-Medium.ttf'
import './fonts/Roboto-MediumItalic.ttf'
import './fonts/Roboto-Regular.ttf'
import './fonts/Roboto-Thin.ttf'
import './fonts/Roboto-ThinItalic.ttf'


// import "./App.css"
import "./index.css"

const styles = {
    button: color => ({
        color,
        borderColor:color,
        backgroundColor:'transparent'
    }),
    notFound:{
        width:'100%', 
        textAlign:'center', 
        color:'232323'
    }

}

const RedirectTo = props =>  {
    window.location.replace(props.url);
    return null;
}

const RedirectToMultiple = props => {

    return(
        <>
            <div style={{width:'100vw', textAlign:'center'}}>

                {Object.entries(props).map(it =>
                    <p>
                        <Button style={styles.button('#232323')} variant="outlined"  type="submit" onClick={()=>window.location.replace(it[1])}>Redirect to {it[0]}</Button>
                    </p>
                )}
            </div>
        </>
    )
}



export default function App() {
        const [email, setEmail] = useState()
        const auth = getAuth()
        useEffect(()=>onAuthStateChanged(auth, user => {
            setEmail(user.email)
        }), [])
        return (
        <BrowserRouter> 
           <SharedStateProvider>
           <FirebaseAuth>
           <AppBar />
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                {email?
                    <>
                    <Route path="/kunduppgifter" element={<Kunduppgifter />} />
                    <Route path="/in" element={<Inlamning />} />
                    <Route path="/in/:kundId" element={<Inlamning />} />
                    <Route path="/sr" element={<ServiceRapport />} />
                    <Route path="/sr/:id" element={<ServiceRapport />} />
                    <Route path="/ut" element={<Utlamning />} />
                    <Route path="/ut/:id" element={<Utlamning />} />
                    <Route path="/installningar" element={<Installningar />} />
                    <Route path="/calc" element={<Calc />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/dokumentation" element={<Dokumentation />} />
                    </>
                    :null}
                <Route path="signin" element={<FirebaseSignin />} />
                <Route path="resetPassword" element={<FirebaseResetPassword />} />
            </Routes>
           </FirebaseAuth>
           </SharedStateProvider>
        </BrowserRouter>
    );
  }
  